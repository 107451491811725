import React from "react";

export const Blogs = () => {
  return (
    <>
      <main id="main" data-aos="fade-in">
        <section id="trainers" className="trainers">
          <div className="container" data-aos="fade-up">
            <div className="row" data-aos="zoom-in" data-aos-delay="100">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <img
                    src="https://find-your-fires.vercel.app/img/ceo.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="member-content">
                    <h4>Jenia Roy Chakraborty</h4>
                    <span>Founder, CEO</span>
                    <div className="social">
                      <a
                        href="https://www.facebook.com/Find-your-Fires-104026358660467/"
                        target="_blank"
                      >
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a
                        href="https://instagram.com/find_your_fires?igshid=YmMyMTA2M2Y="
                        target="_blank"
                      >
                        <i className="bi bi-instagram"></i>
                      </a>
                    </div>
                    <p>
                      "SHE WILL RISE FROM THE ASHES OF HER MISTAKES LIKE A
                      PHOENIX BIRD AND BECOME LEGEND.. "
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member" style={{ border: "1px solid white" }}>
                  <p style={{ textAlign: "left" ,color:"black" }}>
                    2017 সালের শেষের দিক থেকেই আমি শেয়ার বাজারের সাথে পরিচিত
                    হই... প্রথম প্রথম ভয় লাগতো... কিন্তু যত আমি এই বিষয়ে
                    পড়াশোনা শুরু করি ততো শেয়ার বাজার সম্পর্কে আগ্রহ
                    জন্মায়.....আর তার থেকে ভালোবাসা...❤️ একদম নাম মাত্র
                    investment দিয়ে শুরু করি... পরে মাসে 1000-1500 টাকা add
                    করতাম... সেটাকেই kaizen method এবং compound interest দিয়ে
                    ধীরে ধীরে বাড়াতে থাকি.... প্রথম প্রথম অতটা income হোতো
                    না.... কিন্তু আমার পরিচিত কিছু দাদা এবং বন্ধুরা এত সহজ ভাবে
                    বোঝাতো, যে খুব শিগগিরই মোটামুটি উপার্জন শুরু করলাম এবং 2019র
                    সেপ্টেম্বরে এ বিবাহ পরবর্তী যে প্রাইভেট স্কুলে পড়াতাম সেটাও
                    ছেড়ে দিলাম.... শাপে বর হয়ে দাঁড়ায় করোনার লকডাউন এবং সেই
                    সময়ে আমার প্রেগন্যান্সি... সেই অখন্ড অবসর কে কাজে লাগিয়ে
                    শুধু মাত্র নিজেকে এবং নতুন অর্জিত এই skill কে improve করার
                    চেষ্টা করেছিলাম... প্রচুর বই পড়তাম, video দেখতাম.... আস্তে
                    আস্তে মোটামুটি রপ্ত করে ফেলি... প্রথম 1500 টাকা থেকে 1lakh
                    income করার জার্নিটা ছিলো toughest... তারপর আস্তে আস্তে
                    সমস্যা কমতে থাকে... কিন্তু কোনোদিনও হার মানিনি.... তাই
                    পরবর্তীতে যখন অনেকে আমাকে শিখিয়ে দেওয়ার অনুরোধ জানায়,
                    প্রথমে আমি "আমার এতটুকু অভিজ্ঞতায় কি আর পড়াবো" ভেবে
                    পিছিয়ে এলেও, সবার আগ্রহ দেখে এই "Find your Fires" তৈরী
                    করি... যেখানে শুধুমাত্র আমি নই আমার সেই দাদা এবং বন্ধুরাও
                    আমার অনুরোধে ক্লাস নেন... স্টুডেন্ট দের বেস্ট নলেজ দেওয়ার
                    আমাদের এই প্রচেষ্টা আশা করি আমাদের স্টুডেন্টদের profit
                    screenshots এবং review post গুলো তে পরিস্কার ভাবেই বোঝা
                    যায়... 😊
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch ">
                <div className="member" style={{ border: "1px solid white" }}>
                  <p style={{ textaAlign: "left",color:"black" }}>
                    সবার ভেতরের এই "নতুন কিছু করে দেখানোর আগুন" টাকে খুঁজে বের
                    করতে গিয়ে প্রতিমুহূর্তে নিজেদের আগুনটাকেও খুঁজে পাচ্ছি...
                    তাই গতবছরের শুরুতেও যে মেয়েটা 3.5বছরে অর্জিত 40K তুলতে
                    অক্ষম ছিলো, সে আজ নিজের পেজে সগর্বে পোষ্ট দিতে পারে
                    "হ্যাঁ...আমি এক এক দিনে বাড়িতে বসে 47-48k উপার্জন করার
                    ক্ষমতা রাখি.."❤️ হ্যাঁ রোজ হয়তো হয়না, কখনো এর চেয়ে বেশিও
                    হয়, কখনো কম ও হয়, কখনো কখনো লস্ ও হয়.... কারন শেয়ার
                    বাজারের 200টা working day ই তো আর সমান যায় না... আমরা কখনই
                    বলিনি টাকা উপার্জন করা সহজ বা শেয়ার বাজারে investment করা
                    খুব সহজ হবে... এটা অনেকটা বাঘকে পোষ মানানোর মতো... সহজ নয়,
                    কিন্তু অসম্ভব ও নয়.... দরকার সঠিক প্রশিক্ষণ এবং অধ্যবসায়..
                    "SHE WILL RISE FROM THE ASHES OF HER MISTAKES LIKE A PHOENIX
                    BIRD AND BECOME LEGEND.. "  আমাদের "Find Your Fires" এর Share
                    market analysis basic course এ আমরা শুধু রাস্তা
                    দেখাই...নিজের ভেতরের আগুনটাকে চিনতে শেখাই...সেই আগুনে পুড়ে
                    ফিনিক্স তো নিজেদেরই হতে হয়......😊
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
