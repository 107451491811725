import React from "react";
import { CourseSection } from "./course";

export const Training = () => {
  return (
    <div style={{marginTop:"7rem"}}>
      <CourseSection/>
    </div>
  );
};
