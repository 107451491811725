import React, { useEffect } from "react";
import Swiper from "swiper";

export const About = () => {
  return (
    <div>
      {/* ======= About Section ======= */}
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Who are we</h2>
          </div>
          <div className="row">
            <div
              className="col-lg-6 order-1 order-lg-2"
              data-aos="zoom-in"
              data-aos-delay={150}
            >
              <img src="assets/img/who-are-we-V2.jpg" className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
              data-aos="fade-right"
            >
              <h3>
                {" "}
                Empowering Success through High-Quality Education & Dedication
              </h3>
              <p className="fst-italic">
                We are thrilled to share that our first batch started on June
                25th 2021 , and we have over 1000+ happy learners.we are excited
                to continue providing high-quality education and helping our
                students achieve their financial goals .
              </p>
              <ul>
                <li>
                  <i className="bi bi-check-circle" />
                  Launched on June 25, 2021. Over 1000 happy learners.
                </li>
                <li>
                  <i className="bi bi-check-circle" /> Committed to financial
                  education. Team dedication drives success.
                </li>
                <li>
                  <i className="bi bi-check-circle" /> Achieving milestones, our
                  courses empower learners toward financial success.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      {/* End About Section */}
    </div>
  );
};

export const Testimonials = () => {
  useEffect(() => {
    const testimonialsSwiper = new Swiper(".testimonials-slider", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },

        1200: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
    });

    return () => {
      testimonialsSwiper.destroy();
    };
  }, []);

  const testimonialsData = [
    {
      id: 1,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s1.jpeg",
      name: "Jinat Rehman Ali",
      title: "Season - 3",
      quote:
        "Osombhob rokom er bhalo faculty,aamra group a onek i eakdom notum chilam r aamra joto proshno kortam jotobar proshno kortam sobay hasimukhe answer dito...",
    },
    {
      id: 2,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s2.jpeg",
      name: "Rakhi Golui Sarkar",
      title: "Season - 3",
      quote:
        "Share market e ami ekdom e notun but bishoi ti khub e interesting lage amar kache, to Google ar YouTube er doulote ektu ektu porasona suru kori...",
    },
    {
      id: 3,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s3.jpeg",
      name: "Debopama Mukharjee",
      title: "Season - 3",
      quote:
        "I am really new to this field and I have zero knowledge about share market. But finally when the class started it’s all seats well...",
    },
    {
      id: 4,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s4.jpeg",
      name: "Durgaprasad Chakraborty",
      title: "Season - 4",
      quote:
        '"Find your fires" সত্যিই নাম টি খুবই সার্থক। আমি ফেব্রুয়ারি মাসের 21 ,2022 তারিখ থেকে ট্রেড নেওয়া শুরু করেছি আজ পর্যন্ত ৫ টি ট্রেড নিয়েছি...',
    },
    {
      id: 5,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s5.jpeg",
      name: "Kuheli Rajan",
      title: "Season - 3",
      quote:
        "Share market samondhe kono dharona chilo na akhon anekta jenechi aaro jana baki ache ja jenechi find your fires er theke e jenechi khub helpfull team n jenia vishon bhalo...",
    },
    {
      id: 6,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s6.jpeg",
      name: "Piu Chakraborty Chatterjee",
      title: "Season - 4",
      quote:
        "Jenia, Udayan da ,Chiranjit sir r Biplab sir ato valo class niten ,sb questions r answer diten r ato valo bojhaten je share market nie ager viti ta r nai...",
    },
    {
      id: 7,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s7.jpeg",
      name: "Disha Sajjan",
      title: "Season - 4",
      quote:
        '"Sobai share market nam sunlei voy pay r anyoder o voy dekhay"...WHY???? R amar onek jinis janar sekhar ichhe ta ektu besi e. Believe me,FYF er class na korle...',
    },
    {
      id: 8,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s8.jpeg",
      name: "Nirnita Chakraborty Ghoshal",
      title: "Season - 6",
      quote:
        "Share market er proti o ekta interest chilo , sei sutrei find your fires er haat dhora,ekhane jenia di charao Udayan da, Chiranjit da, Biplab da protyekei vison vison valo teacher and obviously helpful...",
    },
    {
      id: 9,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s9.jpeg",
      name: "Satarupa Dey",
      title: "Season - 1",
      quote:
        "Find Your Fires,amr bohu diner ek ichhe ke puron korte sahajyo korche,ami 1st batch er e candidate,Find Your Fires er ata akta speciality.course complete holeo tara poroborti teo bivinyo vabe help kore thake...",
    },
    {
      id: 10,
      imgSrc: "https://findyourfires.vercel.app/assets/img/students/s10.jpeg",
      name: "Titas Chawdhury",
      title: "Season - 6",
      quote:
        "প্রথমে একটু দ্বিধায় ছিলাম online class বলে ,অনেকসময় বিভিন্ন কারণে ক্লাস করতে পারিনি,ক্লাস এর পর পুরো ক্লাস এর video তো পেয়েইছি এবং যখন যা জানতে চেয়েছি খুব সুন্দরভাবে বলে দিয়েছে।...",
    },
    {
      id: 11,
      imgSrc: `assets/img/feedback/avishek.jpg`,
      name: "Avishek Sarkar",
      title: "Season - 11",
      quote:
        "Ami course 11 er student, kono idea chilo na share market e ki hoy ki na hoy,jenia didi and baki sob membar ra khub dhoirjo dhore bishoy gulo sikhiyeche,ato alpo somoye jetuku sikhlam tar jonno FIND YOUR FIRES ke anek thanks..",
    },
    {
      id: 12,
      imgSrc: "assets/img/feedback/debaswati.jpg",
      name: "Debaswati Chakraborty ",
      title: "Season - 5",
      quote:
        "ছোট থেকেই সেভিংসের একটা নেশা আছে। প্রায় বছর ছয়েক আগে, চাকরি জীবনে ঢোকার পর থেকেই মিউচুয়াল ফান্ডে ইনভেস্ট করা শুরু করি। তবে, ডাইরেক্ট শেয়ার মার্কেটে ইনভেস্ট করার একটা সুপ্ত বাসনা ছিলই, অভাব ছিল সঠিক গাইডেন্সের। জিনিয়া দি-কে কয়েকটা ফিমেল গ্রুপে দেখি, ওঁর পোেস্ট ফলো করি সাথে Find Your Fires পেজ। তখনই বুঝেছিলাম, Find Your Fires ই পারবে আমাকে সঠিক গাইড করতে। যোগাযোগ করি দিদির সাথে। ব্যস, আজ আমি Find Your Fires এর একজন মেম্বার। সবে কাজ শুরু করেছি। একটু করে ইনভেস্ট করে টেস্ট করছি। আশা রাখি, ভবিষ্যতে সফল একজন ট্রেডার হতে পারবো।",
    },{
      id: 13,
      imgSrc: "assets/img/feedback/debdulal.jpg",
      name: "Debdulal Mondal ",
      title: "Season - 7 ",
      quote:
        "I recommend to join Find your Fire's to all beginners and those who are in wrong assumptions about Stock Market. Find your Fire's faculty team are excellent I am thankful to them for providing me the knowledge which boosts my confidence.",
    },{
      id: 14,
      imgSrc: "assets/img/feedback/dipjoy.jpg",
      name: "Dipjoy Roy",
      title: "Season - 11",
      quote:
        "The online classes were very informative but the trainning materials that was provided was very interesting, the process of trainning season was so productive, it was great job done by Team FIND YOUR FIRES, I had learn a lot about Share market & I will looking forward to attend more trainning season within few months if possible.",
    },{
      id: 15,
      imgSrc: "assets/img/feedback/manojit.jpg",
      name: "Manojit Roy",
      title: "Season - 7",
      quote:
        "Find your fires a jodi kau k ami sob cheye besi chine thaki se holo Jenia di..tar haat dhorei Find your fires er sthe amr porichoy..share market jinista amr ka6e notun noy tbe share market a ami trading suru kori 8 month age theke..khub akta besi krtm na..tobe proper knowledge chilo na.. youtube dekhe ki6uta sikhe6i..Jenia dir sthe ktha bole ami find your fires ersthe jukto hoi nd onk ki6u sikhi..nijer onk vul bujhte pari..mindset build kori",
    },
    {
      id: 16,
      imgSrc: "assets/img/feedback/mita.jpg",
      name: "Adv. Mita Chatterjee",
      title: "Season - 8",
      quote:
        "Mutual fund r webinar ta akta vishon valo initiative chilo FYF team r torof theke r setao akta vishon nominal charge e Thank you so much to the whole team Onek kichu jante parlam and I hope future e kaje lagate parbo ei bishoi gulo. Whole team k onek onek good wishes Atota helpful platform r erokom guidance class/course ses hoye jawar por o mone hoi akmatro FYF thekei pawa possibile Much Much Love...",
    },
    {
      id: 17,
      imgSrc: "assets/img/feedback/mousumi.jpg",
      name: "Mousumi Banik Bala",
      title: "season - 6",
      quote:
        "I recommend Find Your Fires to all beginners & who are already in stock markets.FYF helps me to overcome my fear about SM & boost my confidence too. I will take this opportunity to thank all faculties of FYF for nourishing us with your valuable knowledge & patience.",
    },
    {
      id: 18,
      imgSrc: "assets/img/feedback/mun.jpg",
      name: "Mun Malick",
      title: "Season - 11",
      quote:
        "As a beginner amar stock market er byapare kono Idea chilo na.sekhane jenia di soho baki sir ra khub valo vabe protita topic dhore dhore bujhiyeche..protyek ta mentor er vison patience..ja sikhechi sob tai find your fires er jonno.thank you..",
    },
    {
      id: 19,
      imgSrc: "assets/img/feedback/tuli.jpg",
      name: "Namrata Chakraborty",
      title: "Season - 3",
      quote:
        "I went through different YouTube videos but couldn't understand the certain terms used in those videos. It felt complicated. And then I came across 'Find your Fires'. The most satisfying thing about this is that the faculties here uses very simple language so that you don't have any problem in understanding. The questions and queries are addressed in a very efficient manner. Even after the completion of the course they remain with you in your trading journey.",
    },
    {
      id: 20,
      imgSrc: "assets/img/feedback/nivedita.jpg",
      name: "Nivedita Kar",
      title: "Season - 11",
      quote:
        " Thanks for the overall classes. It was a wonderful journey, and gathered good knowledge. From zero to some extend. Besh bhalo laglo. Thanks to the entire team of Find Your Fires. keep up the Good work. R jeta na bollei noy, onek baar aageo bolechi abar o bolchi... eto patiently kono teacher k hoyetoh e porate dekhete aaj obdi. Each and every one is so patient and helpfull, Commendable. hats of to the Entire team. All the best to you.",
    },
    {
      id: 21,
      imgSrc: "assets/img/feedback/ruksana.jpg",
      name: "Ruksana Sultana",
      title: "Season - 7",
      quote:
        "আগের বছর একই ladies group এ প্রথম জিনিয়াদ্রি একটা পোেস্ট দেখে যোগাযোগ করি কিন্তু সেই সময় আমার baby ৩মাসের ছিল দিদি নিজের আমাকে wait করতে বলে। আমি বরাবর সাবলম্বী হতে চেয়েছি। WBCS prelims 3rd time দিতে দিতে বিয়ে, অন্য দেশে চলে যাওয়া আবার baby এসবে জড়িয়ে পড়ে থেমে গেছিলাম। তাই আর সময় নষ্ট না করে এই বছর কোর্স করে এখন নিজেই বাচ্চার জন্য হোক নিজের ঘর সাজানোর জিনিস হোক আর বরের পকেটের দিকে তাকাই না।",
    },
    {
      id: 22,
      imgSrc: "assets/img/feedback/shayrei.jpg",
      name: "Shayeri Das",
      title: "Option Season -2",
      quote:
        "Onekdin dhorei ami Jeniar posts follow kortam, khub bhalo lagto lekhar dharon r inspiring way she always talked. Wisdom ta feel kortam tai ekdin phone e Katha bole janalam sikhte chai..bhorti holam options trading course e. Bhishon bhalo legeche course structure, bojhano..Jenia ebong course er sathe jukto every teacher has been great to me...amra bhalo korle onara Khushi hon..it feels so good...Ami nijer choice niye gorbito je emon ekta sundor protisthan e sikhechi ebong agami dineo aro sekhar icche rakhi from Find Your Fires...thank you so much Find Your Fires team.",
    },
    {
      id: 23,
      imgSrc: "assets/img/feedback/sreemoyee.jpg",
      name: "Sreemoyee Mukherjee",
      title: "Season - 6",
      quote:
        "Highly committed team of faculty. Always teaching, guiding and solving doubts with utmost care. Highly recommended.        ",
    },
    {
      id: 24,
      imgSrc: "assets/img/feedback/swarnali.jpg",
      name: "Swarnali Karmakar",
      title: "season - 5",
      quote:
        "J dhoirjo niye fyf ar proti ta mentor class koran akta chotto review to onara deserve koren e.. akhono mone pore class a amader jar ja prosno mone asto seta boka boka holeo mentor ra akbarer jonno birokto hoten na. in fact akhono Jenia di k personal onek query kori didi misti kore reply kore dy.",
    },
    {
      id: 25,
      imgSrc: "assets/img/feedback/namrata.jpg",
      name: "Tuli",
      title: "Season - 7",
      quote:
        "FYF er haat dhore amar jibon er swapno puron hoa6e. Share market course kore onek ki6u sikhe6i.ami ki6ui jantam na share market er bishoye.ei course er sir, mam khub help. kore6en, akhono korchen.jara basic tao jane na tader keo sathe nia ki vabe egiye jete hoy seta FYF theke sikhoniyo bisoy.Thank u FYF",
    },
    {
      id: 26,
      imgSrc: "assets/img/feedback/ankita.jpg",
      name: "Ankita Dutta",
      title: "Season - 2",
      quote:
        "Khub vlo legeche class kore..eto details e bujhiyeche j bujhte kono kosto hoini..ami kichu jantam na find your fires er faculty r jonno aaj ami anek jenechi r sikhechi.. thank u every one..",
    },
    {
      id: 27,
      imgSrc: "assets/img/feedback/mampi.jpg",
      name: "Mampi Bhowmick",
      title: "season - 1",
      quote:
        "I attended first batch of find your fires, all the classes are very helpful, faculties are very supportive .It was really very good experience.thanks for such an informative and concept clearing session...",
    },
    {
      id: 28,
      imgSrc: "assets/img/feedback/mayuri.jpg",
      name: "Mayuri Banerjee",
      title: "Season - 2",
      quote:
        "Share market সমন্ধে কোনো কিছুই জানা ছিল না, আর সত্যি বলতে ভয় ও হতো, কারণ পারিপার্শ্বিক দিক থেকে বরাবর এটা শুনে এসছি যে, share market invest করাটা খুব কঠিন আর এটা kind of gambling। Then জিনিয়ার পোস্ট দেখি Facebook এ, দেখে বেশ interesting লাগে ব্যাপারটা, কিন্তু ভয় হয় এটা ভেবে যে আমি তো Science or Commerce এর student না, আমি কি পারবো? তবুও মনে সাহস জুগিয়ে Course এ ভর্তি হই। আর প্রথম ১-২ টা class এর পর মনে হলো আমিও পারবো। সত্যি খুব ভালো একটা কোর্স আর যারা facalty তারা প্রত্যেকেই ভীষণ ভালো করে বুঝিয়েছেন, কোথাও কোনো অসুবিধে হলে বারবার বুঝিয়েছেন। Course টা করার পর একটাই বলবো যে, যারা ভাবছো কোর্স টা করবো কি করবো না, তাদের বলছি নিশ্চিন্তে করো, আর course শেষ হবার পর তোমরাও বুঝবে যে আমি বা আমরা যারা কোর্স টা করেছি তারা সত্যি বলছি। এখন নিজে invest করা শুরু করবো অল্প অল্প করে, আর Find your Fires এর সকল facalty রা বলেছেন যখনই কোনো অসুবিধে হবে তারা,সব সময় help করবেন।",
    },
    {
      id: 29,
      imgSrc: "assets/img/feedback/nairita.jpg",
      name: "Nairita Saha Datta",
      title: "Season - 2",
      quote:
        "Share Market sombondhe shunechhilam but actually share market e ki korte hoy ba ki kora uchit ba share market e kibhabe invest kora uchit seta ami Course ta join korar por properly jante parlam. faculty ra protteke eto bhalo bujhiechen eto bhalo cooperate korechen prottek student der sathe just unimaginable. prothom bar jokhon Jenia r post dekhechilam bhison interest grow korechhilo jar folshorup ei course join kora. Ami bhison bhabe upokrito ei course join kore.. bhison helpful faculty...ek kathae osshadharon...",
    },
    {
      id: 30,
      imgSrc: "assets/img/feedback/somdutta.jpg",
      name: "Somdutta Pal Chanda",
      title: "season - 3",
      quote:
        "Share market er bapare amar age kono dharonai chilo na...tarpor ekdin fb te jenia r ekti post dekhi about share market... Besh interest laglo... Aro jante eche korlo bishoy ti niye... Jenia r theke find your fires er bapare jante pari and join kori classes gulo te... Ekti kore class er por interest aro barte thake... Faculty r proti ti members khub sohoj bhabe ato kothin bapar ti bojhan..sob doubt clear korte oneek help koren r sob theke special holo classes sesh hoye galeo onara haat chere dey na....sob somay sob problem a pase thaken..... Classes sesh hoar poreo onader guidance pachi.... Thanku find your fires ato kothin bishoy ti sohoj korar jonno...",
    },
  ];

  return (
    <section
      id="testimonials"
      className="testimonials"
      style={{ marginTop: "-9rem" }}
    >
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Students FeedBack</h2>
        </div>

        <div
          className="testimonials-slider swiper"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="swiper-wrapper">
            {testimonialsData.map((testimonial) => (
              <div className="swiper-slide" key={testimonial.id}>
                <div className="testimonial-wrap">
                  <div className="testimonial-item">
                    <img
                      src={testimonial.imgSrc}
                      className="testimonial-img"
                      alt={testimonial.name}
                    />
                    <br />
                    <h3 style={{ marginLeft: "4rem" }}>{testimonial.name}</h3>
                    <h4 style={{ marginLeft: "4rem" }}>{testimonial.title}</h4>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      {testimonial.quote}
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};
