import React from "react";

export const Footer = () => {
  return (
    <div>
      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row text-align-center">
              <div className="col-lg-6 col-md-6 footer-contact">
                <div className="d-flex">
                  <img
                    src="assets\img\fyf logo.jpg"
                    style={{ width: "60px",borderRadius:"5px" }}
                    alt=""
                  />
                  &nbsp;
                  &nbsp;
                  <div>
                    <h3>Find Your Fires</h3>
                    <p style={{ marginTop: "-2rem" }}>be the flame</p>
                  </div>
                </div>
                <br />
                <p>
                  <b>Address : </b> Mainaguri, West Bengal, India <br />
                  <br />
                  <strong>Phone:</strong> +91 8436210040
                  <br />
                  <strong>Email:</strong> support@findyourfires.com
                  <br />
                </p>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" /> <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/contact-us">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/courses">Courses</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/blogs">CEO's</a>
                  </li>
                </ul>
              </div>
            </div>
            <h4>
              DISCLIMER :{" "}
              <span style={{ fontWeight: "300" }}>
                WE ARE NOT SEBI REGISTERED YET, WE DO NOT SUGGEST STOCKS. WE ARE
                NOT RESPONSIBLE FOR YOUR TRADING MISTAKES.
              </span>{" "}
            </h4>
          </div>
        </div>
        <div className="container">
          <div className="copyright-wrap d-md-flex py-4">
            <div className="me-md-auto text-center text-md-start">
              <div className="copyright">
                © Copyright{" "}
                <strong>
                  <span>Find Your Fires</span>
                </strong>
                . All Rights Reserved
              </div>
            </div>
            <div className="social-links text-center text-md-right pt-3 pt-md-0">
              <a
                href="https://www.facebook.com/Find-your-Fires-104026358660467"
                className="facebook"
              >
                <i className="bx bxl-facebook" />
              </a>
              <a
                href="https://instagram.com/find_your_fires?igshid=YmMyMTA2M2Y="
                className="instagram"
              >
                <i className="bx bxl-instagram" />
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer */}
    </div>
  );
};
