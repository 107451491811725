import React from "react";

const courseDetails = [
  {
    id: "tab-1",
    title: "Video content on special topics",
    description:
      "Our basic course on share market technical analysis includes video content on special topics that are not typically covered in other courses. This gives our students a comprehensive understanding of technical analysis and provides them with an edge in the stock market.",
    imageSrc: "assets/img/course-details-tab-1.png",
  },
  {
    id: "tab-2",
    title: "Everyday practical analysis showing",
    description:
      "Our course includes everyday practical analysis showing to help students understand how technical analysis can be applied to real-world market conditions. This helps students gain confidence in their ability to make informed investment decisions.",
    imageSrc: "assets/img/course-details-tab-2.png",
  },
  {
    id: "tab-3",
    title: "Learn basic skills and data analysis",
    description:
      "Students will learn basic skills and data analysis, which is essential for conducting technical analysis in the stock market. This gives our students a strong foundation in technical analysis that they can build upon throughout their trading careers.",
    imageSrc: "assets/img/course-details-tab-3.png",
  },
  {
    id: "tab-4",
    title: "Account & fund management",
    description:
      "The course covers account and fund management, which is an essential aspect of successful trading. Students will learn how to manage their trading accounts and funds to minimize risk and maximize profits.",
    imageSrc: "assets/img/course-details-tab-4.png",
  },
  {
    id: "tab-5",
    title: "10 live classes weekly for 1.5 hrs",
    description:
      "Our course includes 10 live classes every week for 1.5 hours each, giving students ample opportunities to interact with their instructors and ask questions in real-time. This ensures that students have a thorough understanding of the course material and are able to apply it to their trading strategies.",
    imageSrc: "assets/img/course-details-tab-5.png",
  },
  {
    id: "tab-6",
    title: "Build professional mindset",
    description:
      "We focus on building a professional mindset in our students, which is crucial for success in the stock market. Our instructors provide guidance on how to approach trading with a disciplined, goal-oriented mindset that maximizes profitability and minimizes risk.",
    imageSrc: "assets/img/course-details-tab-2.png",
  },
  {
    id: "tab-7",
    title: "Learn risk management",
    description:
      "Our course includes a detailed section on risk management, which is essential for successful trading. Students will learn how to identify and manage risk in the stock market, giving them a greater chance of success.",
    imageSrc: "assets/img/course-details-tab-1.png",
  },
  {
    id: "tab-8",
    title: "Doubt clearing classes",
    description:
      "Our course includes doubt clearing classes where students can ask questions and get answers in real-time. This ensures that students have a thorough understanding of the course material and are able to apply it to their trading strategies with confidence.",
    imageSrc: "assets/img/course-details-tab-3.png",
  },
];

const Basic = () => {
  return (
    <div>
      <section id="course-details" className="course-details">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8">
              <img
                style={{ width: "130%", height: "280px" }}
                src="https://learn.swyftx.com/wp-content/uploads/2022/01/Trading-and-analysis-quiz--500x333.png"
                className="img-fluid"
                alt=""
              />
              <h3>Share Market Basic Course</h3>
              <p>
                Our basic course on share market technical analysis is the best
                option for those who are interested in learning about the
                principles of technical analysis and how it can be applied to
                the stock market. This course is designed to provide a solid
                foundation in technical analysis, covering topics such as chart
                patterns, indicators, trend analysis, and more. The course is
                taught by experienced instructors who are experts in the field
                and have a proven track record of success. The course also
                includes practical exercises and real-world examples to help
                students gain a deep understanding of how to analyze market
                trends and make informed investment decisions.
              </p>
            </div>
            <div className="col-lg-4">
              <div className="course-info d-flex justify-content-between align-items-center">
                <h5>Course Fee</h5>
                <p>₹ 4500 /-</p>
              </div>

              <div className="course-info d-flex justify-content-between align-items-center">
                <h5>Approx Seats</h5>
                <p>70</p>
              </div>

              <div className="course-info d-flex justify-content-between align-items-center">
                <h5>Timings</h5>
                <p>7.30 pm - 9.00 pm (IST)</p>
              </div>
              <div className="course-info d-flex justify-content-between align-items-center">
                <h5>Days</h5>
                <p>Saturday - Sunday</p>
              </div>
              <div className="course-info d-flex justify-content-between align-items-center">
                <h5>Book a Seat</h5>
                <p> <i class="bi bi-telephone"></i>{" "}+91 8436210040</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ------------------------------------- */}
      <section id="faq" className="faq" style={{ marginTop: "-6rem" }}>
        <div className="section-title">
          <h2 style={{ marginTop: "-3rem" }}>Topics Covered</h2>
        </div>
        <div className="container" data-aos="fade-up">
          <div className="faq-list">
            <ul>
              {courseDetails.map((el) => (
                <li data-aos="fade-up" data-aos-delay={100}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target={`#${el.id}`}
                  >
                    {el.title} <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id={el.id}
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>{el.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      {/* _________________________________ */}
    </div>
  );
};

export default Basic;
