import React from "react";

export const CourseSection = () => {
  return (
    <>
     <section id="popular-courses" className="courses">
          <div className="row" data-aos="zoom-in" data-aos-delay={100} style={{marginTop:"-6rem"}}>
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="course-item"> <a href="/basic-course">
                  <img src="https://learn.swyftx.com/wp-content/uploads/2022/01/Trading-and-analysis-quiz--500x333.png" className="img-fluid" alt="..." />
                </a><div className="course-content"><a href="/basic-course">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Basic Course</h4>
                      <p className="price">₹ 4500 /-</p>
                    </div>
                  </a><h3><a href="/basic-course" /><a href="/basic-course">Basic Course</a></h3>
                  <p>Our basic course covers essential topics such as stock valuation, diversification, and risk management to help you start investing in the stock market with confidence. <a href="/basic-course">Read More</a> </p>
                  <div className="trainer d-flex justify-content-between align-items-center">
                    <div className="trainer-rank d-flex align-items-center">
                      <i className="bx bx-heart" />&nbsp;225
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
              <div className="course-item"><a href="/option-course">
                  <img src="https://learn.swyftx.com/wp-content/uploads/2021/09/Crypto-Fundamentals-Analysis-Guide-500x333.png" className="img-fluid" alt="..." />
                </a><div className="course-content"><a href="/option-course">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Option course</h4>
                      <p className="price">₹ 6000 /-</p>
                    </div>
                  </a><h3><a href="/option-course" /><a href="/option-course">Option Trading Course</a></h3>
                  <p>Our option course is designed to help you understand the world of options trading and how it can be used to manage risk and enhance returns in your portfolio. <a href="/option-course">Read More</a> </p>
                  <div className="trainer d-flex justify-content-between align-items-center">
                    <div className="trainer-rank d-flex align-items-center">
                      &nbsp;&nbsp;
                      <i className="bx bx-heart" />&nbsp;112
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
              <div className="course-item"><a href="/basic-course-v2">
                  <img src="https://learn.swyftx.com/wp-content/uploads/2021/10/How-To-Use-TradingView-1-500x333.png" className="img-fluid" alt="..." />
                </a><div className="course-content"><a href="/basic-course-v2">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4>Basic course V2</h4>
                      <p className="price">₹ 4500 /-</p>
                    </div>
                  </a><h3><a href="/basic-course-v2" /><a href="/basic-course-v2">Basic Course V2</a></h3>
                  <p> With classes scheduled throughout the week, mostly on Monday and Tuesday you can easily fit the course into your regular routine <b> without having to sacrifice your weekends</b>. <a href="/basic-course-v2">Read More</a>  </p>
                  <div className="trainer d-flex justify-content-between align-items-center">
                    <div className="trainer-rank d-flex align-items-center">
                      &nbsp;&nbsp;
                      <i className="bx bx-heart" />&nbsp;105
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  );
};
