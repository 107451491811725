import React from "react";
import { Contact } from "./contact";
import { CourseSection } from "./course";

export const Home = () => {
  return (
    <>
      {/* ======= Hero Section ======= */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>FIND YOUR FIRES</h1>
              <p style={{color:"white"}}>be the flame</p>
              <h2>
                Enhance your career prospects in the financial industry, and
                give you access to expert guidance from experienced instructors.
              </h2>
              <div>
                <a href="/courses" className="btn-get-started scrollto">
                 Our Courses
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay={150}
              style={{marginTop:"1.2rem"}}
            >
              {/* <img src="assets\img\fyf_logo-removebg-preview.png" alt="" className="img-fluid" /> */}
              <img
                src="assets/img/hero-img.png"
                className="img-fluid animated"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {/* End Hero */}
      <main id="main">
        {/* ======= About Section ======= */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Who are we</h2>
            </div>
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay={150}
              >
                <img src="assets/img/who-are-we-V1.jpg" className="img-fluid" alt="" />
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
                data-aos="fade-right"
              >
                <h3>
                  {" "}
                  Empowering Success through High-Quality Education & Dedication
                </h3>
                <p className="fst-italic">
                  We are thrilled to share that our first batch started on June
                  25th 2021 , and we have over 1000+ happy learners.we are
                  excited to continue providing high-quality education and
                  helping our students achieve their financial goals .
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check-circle" />
                    Launched on June 25, 2021. Over 1000 happy learners.
                  </li>
                  <li>
                    <i className="bi bi-check-circle" /> Committed to financial
                    education. Team dedication drives success.
                  </li>
                  <li>
                    <i className="bi bi-check-circle" /> Achieving milestones,
                    our courses empower learners toward financial success.
                  </li>
                </ul>
                <a href="/about" className="read-more">
                  Student's Feedbacks <i className="bi bi-long-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
        {/* ======= Counts Section ======= */}
        <section id="counts" className="counts">
          <div className="container">
            <div className="row counters">
              <div className="col-lg-3 col-6 text-center">
                <h1 style={{ color: "white" }}>1000+</h1>
                <p>Students</p>
              </div>
              <div className="col-lg-3 col-6 text-center">
                <h1 style={{ color: "white" }}>4</h1>
                <p>Instructors</p>
              </div>
              <div className="col-lg-3 col-6 text-center">
                <h1 style={{ color: "white" }}>5+ </h1>
                <p>Years of Experience</p>
              </div>
              <div className="col-lg-3 col-6 text-center">
                <h1 style={{ color: "white" }}>24 X 7</h1>
                <p>Students support</p>
              </div>
            </div>
          </div>
        </section>
        {/* End Counts Section */}
        {/* ======= Training Section ======= */}
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Populer Courses</h2>
            </div>
            <CourseSection />
          </div>
        </section>
        {/* End Services Section */}
        {/* ======= Why Us Section ======= */}
        <section id="features" className="features">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Why Choose Us</h2>
              <p>
                we believe in providing exceptional customer service and ongoing
                support to our students. Our team is always available to answer
                any questions and provide assistance to ensure that our students
                get the most out of our courses. Overall, our courses provide a
                high-quality, comprehensive learning experience that can help
                students achieve their financial goals and succeed in the stock
                market.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-1 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-receipt" />
                  <h4>Gain valuable knowledge and skills</h4>
                  <p>
                    Gain valuable knowledge and skills in investment strategies,
                    risk management, and market analysis.
                  </p>
                </div>
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <i className="bx bx-cube-alt" />
                  <h4>Enhance career prospects</h4>
                  <p>
                    Enhance your career prospects in investment banking,
                    financial analysis, and wealth management by completing the
                    course.
                  </p>
                </div>
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <i className="bx bx-images" />
                  <h4>Access to expert guidance</h4>
                  <p>
                    Receive expert guidance and insights into the latest market
                    trends from experienced instructors.
                  </p>
                </div>
              </div>
              <div
                className="image col-lg-6 order-1 order-lg-2 "
                data-aos="zoom-in"
                data-aos-delay={100}
                style={{marginBottom:"-7rem"}}
              >
                <img
                  src="https://st5.depositphotos.com/2466369/66513/v/450/depositphotos_665137844-stock-illustration-stock-market-concept-people-scene.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        {/* End Features Section */}
        {/* <Training /> */}
        <section id="trainers" class="trainers" style={{marginTop:"-5rem"}}>
        <div className="section-title">
              <h2>Founder & CEO</h2>
            </div>
          <div class="container" data-aos="fade-up">
            <div class="row" data-aos="zoom-in" data-aos-delay="100">
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch"></div>

              <div class="col-lg-4 col-md-6 d-flex">
                <div class="member">
                  <img
                    src="https://find-your-fires.vercel.app/img/ceo.jpg"
                    class="img-fluid"
                    alt=""
                  />
                  <div class="member-content">
                    <h4>Jenia Roy Chakraborty</h4>
                    <span>Founder , CEO</span>
                    <p>
                      "SHE WILL RISE FROM THE ASHES OF HER MISTAKES LIKE A
                      PHOENIX BIRD AND BECOME LEGEND.. "
                    </p>
                    <div class="social">
                      <a
                        href="https://www.facebook.com/Find-your-Fires-104026358660467/"
                        target="_blank"
                      >
                        <i class="bi bi-facebook"></i>
                      </a>
                      <a
                        href="https://instagram.com/find_your_fires?igshid=YmMyMTA2M2Y="
                        target="_blank"
                      >
                        <i class="bi bi-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch"></div>
            </div>
          </div>
        </section>
        {/* ======= Frequently Asked Questions Section ======= */}
        <section id="faq" className="faq">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <p>
                Unlock financial success with our Share Market Course—tailored
                for all levels. Covering stock analysis, portfolio management,
                and more, it's accessible with basic financial understanding. No
                prerequisites ; just a Mobile and internet connection. Join us
                today!
              </p>
            </div>
            <div className="faq-list">
              <ul>
                <li data-aos="fade-up" data-aos-delay={100}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    What level of financial knowledge is required to enroll in
                    the share market course?{" "}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Our course caters to all levels, from beginners to
                      experienced investors. We provide comprehensive content
                      suitable for various backgrounds.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                  >
                    How is the course structured, and what topics does it cover?{" "}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The course is structured to cover fundamental and advanced
                      concepts in share market investing. Topics include stock
                      analysis, portfolio management, and risk assessment.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={300}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                  >
                    Are there any prerequisites or specific software/tools
                    required for the course?{" "}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      No specific prerequisites are needed. We recommend basic
                      familiarity with financial terms. Access to a computer and
                      the internet is sufficient for the course.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* End Frequently Asked Questions Section */}
        <Contact />
      </main>
      {/* End #main */}
    </>
  );
};
