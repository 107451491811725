import React from "react";



const courseDetails = [
  {
    id: 'tab-1',
    title: 'Introduction to Option',
    description: 'Our option trading course starts with an introduction to options, including the basics of option trading, how they work, and their key characteristics. This provides students with a solid foundation in option trading.',
    imageSrc: 'assets/img/course-details-tab-1.png',
  },
  {
    id: 'tab-2',
    title: 'Different Option Strategies',
    description: 'Our course covers different option strategies, including both basic and advanced strategies such as covered calls, straddles, and iron condors. This gives students a comprehensive understanding of how options can be used to make profitable trades in the market.',
    imageSrc: 'assets/img/course-details-tab-2.png',
  },
  {
    id: 'tab-3',
    title: 'Option Chain Reading',
    description: 'Our course covers option chain reading, which is essential for analyzing options and making informed trading decisions. Students will learn how to read and interpret option chains to identify the best trading opportunities.',
    imageSrc: 'assets/img/course-details-tab-3.png',
  },
  {
    id: 'tab-4',
    title: 'Option Greek in Detail',
    description: 'Our course covers option Greek in detail, including delta, gamma, theta, and vega. This gives students a deep understanding of how these factors impact option pricing and helps them make more informed trading decisions.',
    imageSrc: 'assets/img/course-details-tab-4.png',
  },
  {
    id: 'tab-5',
    title: 'Hedging Strategies',
    description: 'Our course covers hedging strategies, including how to use options to manage risk and protect against losses. This is an essential aspect of successful option trading and gives students the skills they need to trade with confidence.',
    imageSrc: 'assets/img/course-details-tab-5.png',
  },
  {
    id: 'tab-6',
    title: 'Trade Selection',
    description: 'Our course covers trade selection, including how to identify the best trading opportunities and make informed trading decisions. This gives students the skills they need to make profitable trades in the market.',
    imageSrc: 'assets/img/course-details-tab-2.png',
  },
  {
    id: 'tab-7',
    title: 'Build Trader Mindset',
    description: 'Our course focuses on building a trader mindset, including how to approach trading with discipline, patience, and a goal-oriented mindset. This is essential for success in option trading and helps students develop the skills they need to trade with confidence.',
    imageSrc: 'assets/img/course-details-tab-1.png',
  },
  {
    id: 'tab-8',
    title: 'Learn Trading Psychology',
    description: 'Our course covers trading psychology, including how emotions can impact trading decisions and how to manage them. This gives students the tools they need to overcome common psychological barriers and trade with confidence.',
    imageSrc: 'assets/img/course-details-tab-3.png',
  },
  {
    id: 'tab-9',
    title: 'Learn Risk Management',
    description: 'Our course covers risk management, including how to identify and manage risk in the market. This is essential for successful option trading and helps students minimize losses and maximize profits.',
    imageSrc: 'assets/img/course-details-tab-1.png',
  },
  {
    id: 'tab-10',
    title: 'Video Content on Special Topics',
    description: 'Our course includes video content on special topics, such as advanced option strategies and market analysis. This provides students with a comprehensive understanding of the market and helps them make more informed trading decisions.',
    imageSrc: 'assets/img/course-details-tab-4.png',
  },
];


const Option = () => {
  return (
    <div>
    <section id="course-details" class="course-details">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-8">
            <img
              style={{ width: "130%", height: "280px" }}
              src="https://learn.swyftx.com/wp-content/uploads/2021/09/Crypto-Fundamentals-Analysis-Guide-500x333.png"
              class="img-fluid"
              alt=""
            />
            <h3>Option Trading Course</h3>
            <p>
              Comprehensive coverage of option trading, Our course covers all
              aspects of option trading, including the basics of options, option
              pricing, and advanced option strategies. This comprehensive
              coverage ensures that our students have a thorough understanding
              of option trading and can make informed decisions in the market.
              Experienced instructors, Our course is taught by experienced
              instructors who have a deep understanding of the market and have a
              proven track record of success. They provide real-world examples
              and practical exercises to help students understand complex
              concepts and apply them in real-world scenarios.
            </p>
          </div>
          <div class="col-lg-4">
            <div class="course-info d-flex justify-content-between align-items-center">
              <h5>Course Fee</h5>
              <p>₹ 6000 /-</p>
            </div>

            <div class="course-info d-flex justify-content-between align-items-center">
              <h5>Approx Seats</h5>
              <p>40</p>
            </div>

            <div class="course-info d-flex justify-content-between align-items-center">
              <h5>Timings</h5>
              <p>7.30 pm - 9.00 pm (IST)</p>
            </div>
            <div class="course-info d-flex justify-content-between align-items-center">
              <h5>Days</h5>
              <p>Saturday - Sunday</p>
            </div>
            <div className="course-info d-flex justify-content-between align-items-center">
                <h5>Book a Seat</h5>
                <p> <i class="bi bi-telephone"></i>{" "}+91 8436210040</p>
              </div>
          </div>
        </div>
      </div>
    </section>
    {/* ------------------------------------- */}
    <section id="faq" className="faq" style={{marginTop:"-6rem"}}>
        <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2 style={{ marginTop: "-3rem" }}>Topics Covered</h2>
        </div>
          <div className="faq-list">
            <ul>
              {courseDetails.map((el) => (
                <li data-aos="fade-up" data-aos-delay={100}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target={`#${el.id}`}
                  >
                    {el.title} <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id={el.id}
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>{el.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Option;
