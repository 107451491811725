import React from "react";

export const Navbar = () => {
  return (
    <>
      {/* ======= Header ======= */}
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <a href="/" className="logo">
            <img src="assets\img\fyf logo only.jpg" alt="" className="img-fluid logo-img" style={{borderRadius:"100%",height:"60px"}} />
          </a>
          <h1 className="logo">
            <a href="/" className="logo-text">Find Your Fires </a>
          </h1>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/courses">
                 Courses
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="/blogs">
                CEO's
                </a>
              </li>
              <li>
                <a className="getstarted scrollto" href="/contact-us">
                  {" "}
                  Contact Us
                </a>
              </li>
            </ul>
            <MobileNav />
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
    </>
  );
};

export const MobileNav = () => {
  return (
    <>
      <button
       class="btn btn-ghost" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"
      >
        <i className="bi bi-list mobile-nav-toggle" />
      </button>

      <div
        class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel"
      >
        <div class="offcanvas-header">
        {/* <a href="/" className="logo">
            <img src="assets\img\fyf logo.jpg" alt="" className="img-fluid" style={{borderRadius:"5px"}} />
          </a> */}
          <h5 id="offcanvasTopLabel" className="logo-text-mobile">Find Your Fires</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ol>
            <li data-bs-dismiss="offcanvas">
              <a className="" href="/">
                Home
              </a>
            </li>
            <li data-bs-dismiss="offcanvas">
              <a className="" href="/about">
                About
              </a>
            </li>
            <li data-bs-dismiss="offcanvas">
              <a className="" href="/courses">
               Courses
              </a>
            </li>
            <li data-bs-dismiss="offcanvas">
              <a className=" " href="/blogs">
                CEO's
              </a>
            </li>
            <li data-bs-dismiss="offcanvas">
              <a className="" href="/contact-us">
                {" "}
                Contact Us
              </a>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

