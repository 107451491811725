import React from 'react'
import {Routes,Route} from 'react-router-dom'
import {Home} from './components/home'
import {About} from './components/about'
import {Training} from './components/training'
import {Blogs} from './components/blogs'
import {Job} from './components/job'
import {Contact} from './components/contact'
import Basic from './Pages/Basic'
import BasicV2 from './Pages/BasicV2'
import Option from './Pages/Option'



export const Allroutes = () => {
  return (
    <Routes>
        <Route path={"/"} element={<Home/>}/>
        <Route path={"/about"} element={<About/>}/>
        <Route path={"/courses"} element={<Training/>}/>
        <Route path={"/blogs"} element={<Blogs/>}/>
        <Route path={"/basic-course"} element={<Basic/>}/>
        <Route path={"/option-course"} element={<Option/>}/>
        <Route path={"/basic-course-v2"} element={<BasicV2/>}/>
        <Route path={"/contact-us"} element={<Contact/>}/>
        <Route path={"*"} element={<Home/>}/>
    </Routes>
  )
}
