import './App.css';
import { Allroutes } from './Routes';
import { Footer } from './components/footer';
import { Navbar } from './components/navbar';

function App() {
  return (
    <>
      <Navbar/>
        <Allroutes/>
      <Footer/>
    </>
  );
}

export default App;
