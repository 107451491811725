import React from 'react'

export const Contact = () => {
  return (
    <div>
      {/* ======= Contact Section ======= */}
      <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact Us</h2>
              <p>
              Contacting us can provide valuable resources and insights to help You navigate the complexities of the stock market.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="info-box mb-4">
                  <i className="bx bx-map" />
                  <h3>Our Address</h3>
                  <p>
                    Mainaguri, West Bengal, India
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope" />
                  <h3>Email Us</h3>
                  <p>support@findyourfires.com</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-phone-call" />
                  <h3>Call Us</h3>
                  <p>+91 8436210040</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 ">
              <iframe style={{border:"0", width: "100%", height: "350px"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3561.332547040674!2d88.60821161507031!3d26.542140983319755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e444faa09643b7%3A0xa99de60f7aae56a4!2sMainaguri%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1645014385598!5m2!1sen!2sin" frameborder="0" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
    </div>
  )
}
